import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import React, { Suspense } from "react";
import trip from "./data/trips.json";

const mainRoutes = [
  {
    key: "main",
    path: "/",
    render: (props) => renderAsync(import("./pages/Main.js"), props, false),
  },
];
export default function MainRouter() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Switch>
          {mainRoutes.map(({ key, path, render }) => (
            <Route
              exact
              key={key}
              path={path}
              render={(props) => render(trip)}
            />
          ))}
          <Route
            key="notFound"
            render={() => <div>You might have the wrong route</div>}
            // component={props => renderAsync(() => import('./NotFound'), props)}
          />
        </Switch>
      </Router>
    </Suspense>
  );
}

//to be implemented
const isAuthenticated = () => {
  return true;
};

const renderAsync = (asyncLoad, _props, authRequired = true) => {
  if (authRequired) {
    if (!isAuthenticated()) {
      const to = { pathname: "/auth" };

      return <Redirect to={to} />;
    }
  }
  return <AsyncRenderer component={asyncLoad} componentProps={_props} />;
};

const AsyncRenderer = ({ component, componentProps }) => {
  const LazyComponent = React.lazy(() => component);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent {...componentProps} />
    </Suspense>
  );
};
