import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CssBaseline from '@mui/material/CssBaseline';
import MainRouter from "./Router.js";
import React, { useMemo } from "react";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ["Oswald", "sans-serif"].join(","),
        },
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <MainRouter></MainRouter>
    </ThemeProvider>
  );
}

export default App;
